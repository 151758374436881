import { observer } from 'mobx-react-lite'
import React from 'react'

const Education = () => {
    return (
        <div>
            <h1>Hello</h1>
        </div>
    )
}

export default observer(Education)
